import { useState } from "react";
import BeliBoxLogo from "../BeliBoxLogo/beli-box-logo.component";
import DispatchClick from "../DispatchClick/dispatch-click.component";
import HashTagClick from "../HashTagClick/hash-tag-click.component";
import SocialMediaBar from "../SocialMediaBar/social-media-bar.component";
import "./global-header-menu-mobile-menu.css";
import BeliBoxInfo from "../../Data/belibox-info.json";
const pages = [
	{
		route: "belibox",
		name: "BELI! BOX",
		subpages: [
			{
				route: "belibox#beli-box-content",
				name: BeliBoxInfo.btn1,
			},
			{
				route: "belibox#destinations",
				name: BeliBoxInfo.btn2,
			},
		],
	},
	{ route: "faq", name: "FAQ" },
	{
		route: "agencedevoyages",
		name: "AGENCE DE VOYAGES",
	},
];

const GlobalHeaderMenuMobileMenu = ({
	isMobileMenuOpen,
	handleMobileMenuClick,
	isSelected,
	url,
}) => {
	const [subMenuOpen, setsubMenuOpen] = useState("");
	const isSubMenuOpen = (route) => subMenuOpen === route && "subMenuOpen";
	const handleSubMenuOpenClick = (route) =>
		isSubMenuOpen(route) ? setsubMenuOpen("") : setsubMenuOpen(route);
	return (
		<div className={`mobile-menu ${isMobileMenuOpen ? "open" : ""}`}>
			<div className="mobile-menu-container">
				<div onClick={handleMobileMenuClick}>
					<BeliBoxLogo url={url} fixed={true} />
				</div>
				<div className="mobile-menu-belibox">
					<div className="opensans-extra-bold-well-read-17px mobile-menu-belibox-links">
						{pages.map((p, ix) => (
							<div key={ix}>
								<span className={`${isSelected(p.route)}`}>
									<div className="mobile-menu-belibox-links-submenu">
										<DispatchClick url={p.route}>
											<span onClick={handleMobileMenuClick}>{p.name}</span>
										</DispatchClick>
										{p.subpages && (
											<img
												src={`${url}/open-menu.webp`}
												alt={
													isSubMenuOpen(p.route) ? "Open Menu" : "Close Menu"
												}
												className={`pointer ${
													isSubMenuOpen(p.route) && "inverted"
												}`}
												onClick={() => handleSubMenuOpenClick(p.route)}
											/>
										)}
									</div>
								</span>
								{p.subpages && (
									<ul
										className={`pointer mobile-menu-submenu ${isSubMenuOpen(
											p.route
										)}`}
									>
										{p.subpages.map((sp, ix) => (
											<li key={ix} onClick={handleMobileMenuClick}>
												<HashTagClick
													tag={sp.route}
													className="opensans-extra-bold-well-read-17px no-decoration"
												>
													{sp.name}
												</HashTagClick>
											</li>
										))}
									</ul>
								)}
							</div>
						))}
					</div>
				</div>
				<div className="suivez-nous">
					<span className="opensans-extra-bold-black-pearl-17px">
						SUIVEZ-NOUS :
					</span>
					<SocialMediaBar />
				</div>
			</div>
		</div>
	);
};

export default GlobalHeaderMenuMobileMenu;
