import ImageService from "../../Services/image.service";
import "./arrow-button.css";

const ArrowButton = ({ buttonText, horizontal = true }) => {
	const urlBase = ImageService.ImageUrl("/webp");

	const flecha1 = `${urlBase}/trazado-13@1x.webp`;
	const flecha2 = `${urlBase}/trazado-14@1x.webp`;
	return (
		<div className={`rectngulo-81 ${!horizontal ? "vertical" : ""}`}>
			<span className="raleway-medium-black-pearl-16px">{buttonText}</span>
			<img
				loading="lazy"
				alt="arrow1"
				className="trazado-arrow"
				src={flecha1}
			/>
			<img
				loading="lazy"
				alt="arrow2"
				className="trazado-arrow"
				src={flecha2}
			/>
		</div>
	);
};

export default ArrowButton;
