import BeliData from "../../Services/beli.service";
import { InstagramHeaderMenuProfile } from "../GlobalHeaderMenuProfile/global-header-menu-profile.component";
// import Telefon from "../Telefon/telefon.component";

const GlobalHeaderMenuContact = ({
	setisMobileMenuOpen,
	isMobileMenuOpen,
	url,
	user,
}) => {
	return (
		<div className="contactHeaderMenu">
			<a href="tel:1234567">
				<div className="grupo-178-1 pointer">
					<div className="overlap-group-2 circle">
						<img
							loading="lazy"
							alt="Phone"
							src={`${url}/header/telephone.webp`}
						/>
					</div>
					<div className="phone-1 opensans-bold-white-18px">
						{BeliData.Telefon()}
					</div>
				</div>
			</a>
			<a href={`mailto:${BeliData.MailContact()}`}>
				<div className="grupo-179">
					<div className="circle">
						<img
							loading="lazy"
							alt="email"
							// className="email-84"
							src={`${url}/header/mail.webp`}
						/>
					</div>
					<div className="text-8 opensans-bold-white-18px">
						{BeliData.MailContact()}
					</div>
				</div>
			</a>
			<div className="profile-icon-mobile">
				{/* <GlobalHeaderMenuProfile
					key={"component-profile-icon-mobile"}
					user={user}
				/> */}
				<InstagramHeaderMenuProfile />
			</div>
			<div
				className="contact-header-hamburguer circle pointer"
				onClick={setisMobileMenuOpen}
			>
				{isMobileMenuOpen ? (
					<img
						src={`${url}/header/close.webp`}
						alt="Close Mobile Menu"
					/>
				) : (
					<img
						src={`${url}/header/hamburger.webp`}
						alt="Mobile Menu"
					/>
				)}
			</div>
		</div>
	);
};

export default GlobalHeaderMenuContact;
