export default class FetchService {
	static #HeadersList = {
		Accept: "*/*",
		"User-Agent": "Thunder Client (https://www.thunderclient.io)",
		"Content-Type": "application/json",
	};
	static #Codes = [
		{
			endpoint: "GetPoints",
			code: "K3LdpXO6MALRXAmStWyFC9oNghzBcUGb6GjiWUM0BJRdLJWU35Xfrw==",
		},
		{
			endpoint: "GetActivities",
			code: "4uWGiM5jr8EmaGFdE5a8if9xC46WqDXPIMjNRHCkYEwCQq5vzfE2pQ==",
		},
		{
			endpoint: "GetMonParrainage",
			code: "Re/XC6XRylaXdRIlro1cgsmihuf4T0mg3rw8RSiuVjRwxiZlcwCHVw==",
		},
		{
			endpoint: "GetVoyages",
			code: "CyGxL3FgXnhw8rhdQKlmMAfzk5nlWvH31ocO4YsYccMb0kfBQMTxGg==",
		},
		{
			endpoint: "Register",
			code: "Y7lieOfwTeKuqOcrAa7AyjTspznDQX/Dm7OTgbAPrUIe/GXRQtgXVQ==",
		},
		{
			endpoint: "NewsLetterInscrire",
			code: "4h3dAWtBYXarec5u3X8wmmO6MQTExfEQLHtRlYdjPnVD8yB0jtaCIA==",
		},
		{
			endpoint: "GetReverse",
			code: "jl/rDpI8ie3cQJWIjfAUdgCUA7l63OrObh4FM8mgsyUoYposaqaUNw==",
		},
		{
			endpoint: "GetToken",
			code: "O7zQfbWVlqbBOvijqWOt5ln5xYTOrGJybc0i6Jc0xuaR/Ojc/9m3Ew==",
		},
		{
			endpoint: "RegisterPayment",
			code: "UCtWKtV7WdusZmQraHlcbO/i1djB4KH0KngdyVsuwNdvZv2FsuAcbA==",
		},
	];
	static #Server =
		process.env.NODE_ENV === "development"
			? "http://localhost:7071/api"
			: `${window.location.protocol}//beli-voyage-api.azurewebsites.net/api`;
	static #GetCode = (apiFunction) => {
		const code = FetchService.#Codes.filter(
			(c) => c.endpoint === apiFunction
		);
		if (code && code.length > 0) {
			return code[0].code;
		}
		return null;
	};

	static Fetch = async (
		api,
		body,
		method = "POST",
		functionName = null,
		timeOut = 10000
	) => {
		const controller = new AbortController();
		const id = setTimeout(() => controller.abort(), timeOut);

		if (functionName) {
			const code = FetchService.#GetCode(functionName);
			api += `${code ? `?code=${code}` : ""}`;
		}
		let options = {
			method: method,
			headers: FetchService.#HeadersList,
			signal: controller.signal,
		};
		if (body) options = { ...options, body: JSON.stringify(body) };
		const resp = await fetch(`${FetchService.#Server}/${api}`, options);
		const result = await resp.json();
		clearTimeout(id);
		if (result.success && result.success !== 0) {
			return result;
		} else {
			if (result.success !== undefined) {
				console.error("Error Fetching -> ", api, options, result);
			} else {
				console.log(`Method ${api} has no success, add it.`);
			}
			return result;
		}
	};
}
