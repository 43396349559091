import ImageService from "../../Services/image.service";
import DispatchClick from "../DispatchClick/dispatch-click.component";

const BeliBoxLogo = ({ url = ImageService.BaseUrl, fixed }) => {
	return (
		<DispatchClick className="grupo-176 pointer" url={"index"}>
			<div className="grupo-175">
				<div className="trazado-container">
					<img
						loading="lazy"
						alt="LOGO"
						src={`${url}/${fixed ? "beli-read" : "beli-transparent"}.webp`}
					/>
				</div>
			</div>
		</DispatchClick>
	);
};

export default BeliBoxLogo;
