import { history } from '../App';

const linksReducer = (state, action) => {
	let array;
	switch (action.type) {
		case "REDIRECT":
			array = [...state.links];
			array.push(action.payload);
            history.push(action.payload)
			return {
				...state,
				links: array,
			};
		default:
			return state;
	}
};

export default linksReducer;
