import { HashLink } from "react-router-hash-link";

const HashTagClick = (props) => {
	const { tag, children, className = null } = props;
	return (
		<HashLink to={tag} className={className && className}>
			{children}
		</HashLink>
	);
};

export default HashTagClick;
