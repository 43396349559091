import React from "react";
import ArrowButton from "../../components/ArrowButton/arrow-button.component";
import DispatchClick from "../../components/DispatchClick/dispatch-click.component";
import DivWithBackground from "../../components/DivWithBackground/div-with-background.component";
import TucanLogo from "../../components/TucanLogo/tucan-logo.component";
import ImageService from "../../Services/image.service";
import "./not-found.css";

function NotFound() {
	const notFoundImage = ImageService.ImageUrl(
		"/webp/road-mont-saint-michel-sunset-normandy-france.webp"
	);
	return (
		<div className="container-center-horizontal">
			<div className="index screen">
				<div className="index-1">
					<div className="not-found-container">
						<DivWithBackground
							className="not-found-image"
							source={notFoundImage}
						/>
						<div className="not-found-text">
							<div className="not-found-title">
								<span>OUPS ! TOUKI S’EST PERDU…</span>
							</div>
							<div className="not-found-subtitle">
								<span>PAGE INTROUVABLE</span>
							</div>
						</div>
						<div className="not-found-aide">
							<span className="aidez-le-retrouver-son-chemin">
								Aidez-le à retrouver son chemin
							</span>
						</div>
						<div className="not-found-arrows">
							<DispatchClick url="/" className="not-found-envolez">
								<ArrowButton buttonText="ENVOLEZ-VOUS VERS LA PAGE D’ACCUEIL" />
							</DispatchClick>
							<DispatchClick url="/" className="not-found-retour">
								<ArrowButton buttonText="RETOUR" />
							</DispatchClick>
						</div>
						<TucanLogo className="not-found-tucan" />
					</div>
				</div>
			</div>
		</div>
	);
}

export default NotFound;
