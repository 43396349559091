import UserService from "../Services/user.service";

const userReducer = (state, action) => {
	// let array;
	// console.log("user reducer", action.type);
	switch (action.type) {
		case "REFRESH_LOGIN":
			const user = UserService.GetUserFromLocalStorage();
			// console.log(user);
			if (user) {
				// console.log(moment(user.timestamp).add(1, "h"), moment(new Date()));
				return { ...state, user };
			}
			return { ...state };
		case "LOGIN":
			if (state.user && Object.keys(state.user).length === 1) {
				return {
					...state,
				};
			}
			localStorage.setItem(
				"user",
				JSON.stringify({ ...action.payload, timestamp: new Date() })
			);
			return {
				...state,
				user: action.payload,
			};
		case "LOGOUT":
			localStorage.removeItem("user");
			return {
				...state,
				user: {},
			};
		case "UPDATE_USER_DATA":
			localStorage.removeItem("user");
			localStorage.setItem(
				"user",
				JSON.stringify({ ...action.payload, timestamp: new Date() })
			);
			return { ...state, user: action.payload };
		default:
			return state;
	}
};

export default userReducer;
