import moment from "moment";
import { toast } from "react-toastify";
import FetchService from "./fetch.service";

export default class UserService {
	static #Fetch = FetchService.Fetch;
	static Login = async (user, pass) => {
		if (!user || !pass) return;

		try {
			const userObject = {
				email: user,
				password: pass,
			};
			return await this.#Fetch("login", userObject);
		} catch (error) {
			console.error("Login ->", error);
		}
	};
	static Register = async (obj) => {
		try {
			return await this.#Fetch("client", obj, "POST", "Register");
		} catch (error) {
			console.error("Register ->", error);
		}
	};
	static UpdateInformations = async (user, obj) => {
		try {
			const userPut = {
				...user,
				...obj,
			};
			const res = await this.#Fetch(
				"client",
				{ client: { ...userPut } },
				"PUT"
			);
			return { ...res };
		} catch (error) {
			console.error("UpdateInformations ->", error);
		}
	};
	static UpdateAdress = async (user, obj) => {
		try {
			const userPut = {
				...user,
				...obj,
			};
			const res = await this.#Fetch(
				"client",
				{ client: { ...userPut } },
				"PUT"
			);
			return { ...res };
		} catch (error) {
			console.error("UpdateAdress ->", error);
		}
	};
	static ActivateBox = async (boxNumber, client_numero, client_id) => {
		if (!boxNumber || !client_numero || !client_id) return;
		try {
			const boxObj = {
				box_numero: boxNumber,
				client_numero: client_numero,
				id_client: client_id,
			};
			return await this.#Fetch("box", boxObj);
		} catch (error) {
			console.error("ActivateBox ->", error);
			toast.error(
				"Erreur lors de l'activation de Beli ! Box, veuillez réessayer plus tard."
			);
		}
	};
	static GetPoints = async (client_id, client_numero) => {
		if (!client_id || !client_numero) return 0;
		try {
			const api = `point/${client_id}/${client_numero}`;
			const result = await this.#Fetch(api, null, "GET", "GetPoints");
			return result.nombre_points;
		} catch (err) {
			console.error(err);
			return 0;
		}
	};
	///api/voyage/{client_numero}
	static GetVoyages = async (client_numero) => {
		if (!client_numero) return [];
		try {
			const api = `voyage/${client_numero}`;
			const res = await this.#Fetch(api, null, "GET", "GetVoyages");
			return res.voyage;
		} catch (error) {
			console.error("GetActivities", error);
			return [];
		}
	};
	static GetActivities = async (id_client, client_numero) => {
		if (!id_client || !client_numero) return [];
		try {
			const api = `client/activity/${id_client}/${client_numero}`;
			const res = await this.#Fetch(api, null, "GET", "GetActivities", 15000);
			return res;
		} catch (error) {
			console.error("GetActivities", error);
			return [];
		}
	};
	static GetMonParrainage = async (id_client) => {
		if (!id_client) return "";
		try {
			const api = `client/referralcode/${id_client}`;
			const res = await this.#Fetch(api, null, "GET", "GetMonParrainage");
			return res.referralcode;
		} catch (error) {
			console.error("GetMonParrainage", error);
			return "";
		}
	};
	static GetMonParrainageActivities = async (id, client_numero) => {
		if (!id || !client_numero) return [];

		try {
			const results = await UserService.GetActivities(id, client_numero);
			const parrianageActivities = results.details.filter(
				(d) => d.activity === "parrainage"
			);
			return {
				...results,
				details: [...parrianageActivities],
			};
		} catch (err) {
			console.error("GetMonParrainageActivities", err);
			return [];
		}
	};
	static GetPassangersForDossier = async (dossier_numero) => {
		try {
			const api = `paxliste/${dossier_numero}`;
			const res = await this.#Fetch(api, null, "GET");
			return res.pax_liste;
		} catch (error) {
			return [];
		}
	};
	static GetLastBox = async (id, client_numero) => {
		const noBox = {
			box_numero: "-",
			date_creation: "-",
		};
		try {
			if (!id || !client_numero) return noBox;

			const api = `box/${id}/${client_numero}`;
			const res = await this.#Fetch(api, null, "GET");
			if (res.results.length > 0) {
				const box = res.results.sort((b) => b.date_creation)[
					res.results.length - 1
				];
				return box;
			}
			return noBox;
		} catch (error) {
			console.error("GetLastBox", error);
			return noBox;
		}
	};
	static ForgotPassword = async (email) => {
		try {
			const res = await this.#Fetch("client/resetpassword", email, "POST");
			return res;
		} catch (error) {
			console.error("ForgotPassword", error);
			return null;
		}
	};
	static UpdatePassword = async (email, obj) => {
		try {
			const objPut = {
				email,
				password: obj.currentPassword,
				newPassword: obj.newPassword,
			};
			const res = await this.#Fetch(`client/password`, objPut);
			if (res.success === 1) return res;
			return { success: 0 };
		} catch (error) {
			return { success: 0 };
		}
	};
	static IsUserLoggedIn = (user) => user && Object.keys(user).length > 0;

	static GetUserFromLocalStorage = () => {
		try {
			const strUser = localStorage.getItem("user");
			if (strUser) {
				const user = JSON.parse(strUser);
				const sessionExpired =
					moment(user.timestamp).add(1, "h") < moment(new Date());
				if (!sessionExpired) return user;
			}
		} catch (error) {
			// console.log(error);
		}
		try {
			localStorage.removeItem("user");
		} catch (error) {}

		return null;
	};
}
