import BeliData from "../../Services/beli.service";
import ImageService from "../../Services/image.service";
import "./social-media-bar.css";

const SocialMediaBar = () => {
	const x001Facebook = ImageService.ImageUrl("/webp/001-facebook@1x.webp");
	const grupo196 = ImageService.ImageUrl("/webp/trazado-56@1x.webp");
	return (
		<div className="flex-row-4">
			<a
				href={BeliData.Facebook()}
				target="_blank"
				rel="noreferrer"
				className="pointer"
			>
				<img loading="lazy" alt="Facebook" src={x001Facebook} />
			</a>
			<a
				href={BeliData.Instagram()}
				target="_blank"
				rel="noreferrer"
				className="pointer"
			>
				<img
					src={`${ImageService.BaseUrl}/instagram.webp`}
					loading="lazy"
					alt="Instagram"
				/>
			</a>
			<a
				href={BeliData.YouTubeChannel()}
				target="_blank"
				rel="noreferrer"
				className="pointer"
			>
				<img
					src={`${ImageService.BaseUrl}/youtube.webp`}
					alt="YouTube"
					loading="lazy"
				/>
			</a>
			<a
				href="https://g.page/BeliVoyages?share"
				target="_blank"
				rel="noreferrer"
				className="pointer"
			>
				<img src={grupo196} alt="Google+" loading="lazy" />
			</a>
		</div>
	);
};

export default SocialMediaBar;
