import React, { useRef, useEffect } from "react";

export default function ClickOutsideAlertWrapper(props) {
	const wrapperRef = useRef(null);
	const { isMobileMenuOpen, handleMobileMenuClick } = props;
	useEffect(() => {
		function handleClickOutside(event) {
			//no es el item
			if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
				//el menu esta abierto
				if (isMobileMenuOpen) {
					//no es el boton para cerrar el menu
					if (!event.target.alt || event.target.alt !== "Close Mobile Menu") {
						handleMobileMenuClick();
					}
				}
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
		// eslint-disable-next-line
	}, [isMobileMenuOpen]);

	return (
		<div id="click-wrapper" ref={wrapperRef}>
			{props.children}
		</div>
	);
}
