import styled from "styled-components";

export const GlobalHeaderMenuDisconnectContainer = styled.div`
	position: relative;
	left: -7rem;
	display: block;
	border-radius: 8px;
	width: 175px;
	text-align: center;
	opacity: 0;
	transition: opacity 0.5s;
	z-index: 19999;
`;

export const GlobalHeaderMenuDisconnectArrow = styled.div`
	height: 0;
	width: 0;
	border-left: 7.5px solid transparent;
	border-right: 7.5px solid transparent;
	border-bottom: 7.5px solid rgb(203, 100, 83);
	position: relative;
	left: 75%;
`;
export const GlobalHeaderMenuDisconnect = styled.div`
	color: white;
	background-color: rgb(203, 100, 83) !important;
	font-family: "Raleway";
	padding: 0.5rem;
	width: auto;
	text-align: flex-start;
	white-space: none;
	border-radius: 8px;
`;
