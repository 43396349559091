import styled from "styled-components";

export const ConditionArticleContainer = styled.div`
	display: grid;
	grid: 1fr / 1fr;
	gap: 0.5rem;
    & ul {
        list-style: inside;
    }
    & ul li {
        margin: 0.25rem 1rem;
    }
    & p {
        display: inline-block;
        white-space: normal;
        word-wrap: break-word;
    }
`;
