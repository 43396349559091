import { Fragment } from "react";
import { Parallax } from "react-parallax";
import { ImageSettedDiv } from "./div-with-background.styled";

const sizes = [450, 800, 1280];

const chooseImage = (source) => {
	const { innerWidth } = window;
	if (source) {
		if (innerWidth <= sizes[0]) return source;
		if (innerWidth <= sizes[1]) return source.replace(".webp", "@1x.webp");
		if (innerWidth <= sizes[2]) return source.replace(".webp", "@2x.webp");
		return source.replace(".webp", "@3x.webp");
	}
	return "";
};

const DivWithBackground = ({
	source,
	className = null,
	children = null,
	parallax = 0,
	overlay = null,
}) => {
	const selectedSource = chooseImage(source);

	return (
		<Fragment>
			{parallax === 0 ? (
				<ImageSettedDiv
					loading="lazy"
					imageSource={selectedSource}
					className={className}
				>
					{overlay && (
						<div
							className="overlay"
							style={{
								width: "100%",
								height: "100%",
								backgroundColor: overlay,
							}}
						></div>
					)}

					{children && children}
				</ImageSettedDiv>
			) : (
				<Parallax
					blur={0}
					bgImage={selectedSource}
					bgImageAlt="the cat"
					strength={parallax}
					className={className}
				>
					{children && children}
				</Parallax>
			)}
		</Fragment>
	);
};

export default DivWithBackground;
