import linkReducer from "./links.reducer";
import headerReducer from "./header.reducer";
import userReducer from "./user.reducer";

const combineReducers = (slices) => (state, action) =>
	Object.keys(slices).reduce(
		(acc, prop) => ({
			...acc,
			[prop]: slices[prop](acc[prop], action),
		}),
		state
	);
export const initialState = {
	linkReducer: { links: [] },
	headerReducer: { fixed: false },
	userReducer: { user: {} },
};

export const rootReducer = combineReducers({
	linkReducer,
	headerReducer,
	userReducer,
});
