const headerReducer = (state, action) => {
	// console.log(state);
	switch (action.type) {
		case "REGULAR": {
			return {
				...state,
				fixed: false,
			};
		}
		case "FIXED":
			return {
				...state,
				fixed: true,
			};
		case "SWITCH":
			return {
				...state,
				fixed: !state,
			};
		default:
			return state;
	}
};
export default headerReducer;
