const CenteredContainer = (props) => {
	const { children } = props;
	return (
		<div className="container-center-horizontal">
			<div className="index screen">
				<div className="index-1">{children}</div>
			</div>
		</div>
	);
};

export default CenteredContainer;
