import parse from "html-react-parser";
import { ConditionArticleContainer } from "./condition-article.styled";

const ConditionArticle = ({ article }) => {
	const { title, description } = article;
	return (
		<ConditionArticleContainer>
			<p className="opensans-bold-black-pearl-20px underline">
				{title && title}
			</p>
			<span className="opensans-normal-black-pearl-16px">
				{parse(description)}
			</span>
		</ConditionArticleContainer>
	);
};

export default ConditionArticle;
