import moment from "moment";
import data from "../Data/beli.data.json";

export default class BeliData {
	static Telefon = () => data.telefon;
	static TelefonLink = (className = "black") => (
		<a className={className} href={`tel:${data.telefon}`}>
			{data.telefon}
		</a>
	);
	static Horario = (firstUpper = false) => {
		if (firstUpper)
			return data.horario.charAt(0).toUpperCase() + data.horario.slice(1);
		return data.horario;
	};
	static Address = () => data.address;
	static AddressLink = (className = "black") => {
		return (
			<a
				className={`no-decoration ${className}`}
				href={this.Address().gmap}
				target="_blank"
				rel="noreferrer"
			>
				{this.Address().actual}
			</a>
		);
	};
	static GoogleReview = data.googleReview;
	static GooglePage = () => data.GooglePage;
	static BeliDateFormat = "DD/MM/YYYY";
	static GetFormatedDate = (date) => moment(date).format(this.BeliDateFormat);
	static YouTubeVideoId = data.youTubeVideo;
	static YouTubeVideoEmbed = `https://www.youtube.com/embed/${BeliData.YouTubeVideoId}`;
	static YouTubeVideoWatch = `https://www.youtube.com/watch/${BeliData.YouTubeVideoId}`;
	static YouTubeChannel = () => data.YouTubeChannel;
	static Instagram = (lang = null) => `${data.Instagram}${lang ? lang : ""}`;
	static Facebook = () => data.Facebook;
	static MailContact = () => data.MailContact;
}
