import CenteredContainer from "../../components/CenteredContainer/centered-container.component";
import Condition from "../../components/Condition/condition.component";
import ConditionsData from "../../Data/conditions.json";
import { ConditionsContainer } from "./conditions.styled";

const Conditions = () => {
	return (
		<CenteredContainer>
			<ConditionsContainer>
				{ConditionsData &&
					ConditionsData.map((c, ix) => (
						<Condition
							title={c.title}
							articles={c.articles}
							key={`condition-${ix}`}
						/>
					))}
			</ConditionsContainer>
		</CenteredContainer>
	);
};

export default Conditions;
