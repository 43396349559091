import ImageService from "../../Services/image.service";
import "./tucan-logo.css";
const TucanLogo = ({ className = "" }) => {
	return (
		<img
			loading="lazy"
			alt="Tucan BeliBox"
			src={ImageService.ImageUrl("/webp/Tucan.webp")}
			className={className}
		/>
	);
};

export default TucanLogo;
