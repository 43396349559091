import styled from "styled-components";

export const ConditionsContainer = styled.div`
	padding: 6rem;
	margin-top: 50px;
	display: grid;
	position: relative;
	grid: 1fr / 1fr;
	gap: 2rem;
	width: 100%;
	line-height: 1.5rem;
	white-space: normal;
	word-break: break-word;
	@media only screen and (max-width: 800px) {
		padding: 3rem;
	}
	@media only screen and (max-width: 450px) {
		padding: 3rem 0.5rem;
	}
`;
