import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import BeliData from "../../Services/beli.service";
import ImageService from "../../Services/image.service";
import DispatchClick from "../DispatchClick/dispatch-click.component";
import "./global-header-menu-profile.css";
import {
	GlobalHeaderMenuDisconnect,
	GlobalHeaderMenuDisconnectArrow,
	GlobalHeaderMenuDisconnectContainer,
} from "./global-header-menu-profile.styled";

const imgUrl = ImageService.BaseUrl;

export const InstagramHeaderMenuProfile = () => {
	return (
		<div
			style={{ position: "relative" }}
			className="overlap-group2-2 circle"
		>
			<a href={BeliData.Instagram()} target="_blank" rel="noreferrer">
				<img
					loading="lazy"
					alt="Profile"
					src={`${imgUrl}/header/icone-instagram-menu.webp`}
				/>
			</a>
		</div>
	);
};

const GlobalHeaderMenuProfile = ({ user }) => {
	const [, dispatch] = useContext(AppContext);
	const [userLogged, setuserLogged] = useState(false);
	const [url, seturl] = useState("login");

	useEffect(() => {
		const userLoggedIn = (user) => user && Object.keys(user).length > 0;
		if (user) {
			setuserLogged(userLoggedIn(user));
		}
	}, [user]);

	useEffect(() => {
		seturl(userLogged ? "userportal" : "login");
	}, [userLogged]);

	const handleLogout = () => {
		dispatch({ type: "LOGOUT" });
	};

	return (
		<DispatchClick url={url}>
			<div
				style={{ position: "relative" }}
				className={`overlap-group2-2 circle ${
					userLogged ? "logged-in" : ""
				}`}
			>
				<img
					loading="lazy"
					alt="Profile"
					src={`${imgUrl}/header/icone-instagram-menu.webp`}
				/>
				<GlobalHeaderMenuDisconnectContainer
					className="global-header-menu-disconnect-hover"
					onClick={handleLogout}
				>
					<GlobalHeaderMenuDisconnectArrow />
					<GlobalHeaderMenuDisconnect>
						<span className="raleway-medium-white-16px">
							SÉ DÉCONNECTER
						</span>
					</GlobalHeaderMenuDisconnect>
				</GlobalHeaderMenuDisconnectContainer>
			</div>
		</DispatchClick>
	);
};

export default GlobalHeaderMenuProfile;
