import { Fragment, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../App";
import ImageService from "../../Services/image.service";
import BeliBoxLogo from "../BeliBoxLogo/beli-box-logo.component";
import ClickAlertWrapper from "../ClickAlertWrapper/click-alert-wrapper.component";
import DispatchClick from "../DispatchClick/dispatch-click.component";
import GlobalHeaderMenuContact from "../GlobalHeaderMenuContact/global-header-menu-contact.component";
import GlobalHeaderMenuMobileMenu from "../GlobalHeaderMenuMobileMenu/global-header-menu-mobile-menu.component";
import { InstagramHeaderMenuProfile } from "../GlobalHeaderMenuProfile/global-header-menu-profile.component";
import "./GlobalHeaderMenu.css";
// @ts-ignore
import "./custom.css";

function GlobalHeaderMenu({ fixed = false }) {
	const [isMobileMenuOpen, setisMobileMenuOpen] = useState(false);

	const [state] = useContext(AppContext);

	const { user } = state.userReducer;

	const location = useLocation();

	const isSelected = (option) => {
		const { pathname } = location;
		return pathname.substr(1) === option ? "selectedMenu" : "";
	};
	const pages = [
		{ route: "belibox", name: "BELI! BOX", class: "beli-box" },
		{ route: "faq", name: "FAQ", class: "faq" },
		{
			route: "agencedevoyages",
			name: "AGENCE DE VOYAGES",
			class: "agence-de-voyages",
		},
	];
	const url = ImageService.BaseUrl;
	const handleMobileMenuClick = () => {
		setisMobileMenuOpen(!isMobileMenuOpen);
	};
	return (
		<Fragment>
			<div className={`global-header-menu ${fixed ? "fixed" : null}`}>
				<BeliBoxLogo url={url} fixed={fixed} />
				<GlobalHeaderMenuContact
					setisMobileMenuOpen={handleMobileMenuClick}
					isMobileMenuOpen={isMobileMenuOpen}
					url={url}
					user={user}
				/>
				<div className={`grupo-177 opensans-extra-bold-white-17px`}>
					{pages.map((p, ix) => (
						<DispatchClick
							url={p.route}
							key={ix}
							className={`${p.class} pointer`}
						>
							<span className={isSelected(p.route)}>
								{p.name}
							</span>
						</DispatchClick>
					))}
				</div>
				<div className="profile-icon">
					{/* <GlobalHeaderMenuProfile key={"component-profile-icon"} user={user} /> */}
					<InstagramHeaderMenuProfile />
				</div>
			</div>
			<ClickAlertWrapper
				isMobileMenuOpen={isMobileMenuOpen}
				handleMobileMenuClick={() => setisMobileMenuOpen(false)}
			>
				<GlobalHeaderMenuMobileMenu
					isSelected={isSelected}
					handleMobileMenuClick={handleMobileMenuClick}
					isMobileMenuOpen={isMobileMenuOpen}
					url={url}
				/>
			</ClickAlertWrapper>
		</Fragment>
	);
}

export default GlobalHeaderMenu;
