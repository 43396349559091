import { useContext } from "react";
import { AppContext } from "../../App";

const DispatchClick = (props) => {
	const [, dispatch] = useContext(AppContext);
	const { children, url, search = null, className } = props;

	const handleClick = () => {
		dispatch({
			type: "REDIRECT",
			payload: {
				pathname: url,
				search: search,
			},
		});
	};
	return (
		<div onClick={handleClick} className={[className, "pointer"].join(" ")}>
			{children}
		</div>
	);
};

export default DispatchClick;
