import React from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import SEOData from "../../Data/seo.json";

const seo = SEOData;

const SEO = () => {
	const { pathname, search } = useLocation();

	const getSeoItem = React.useMemo(() => {
		let item = seo.find((s) => s.page.indexOf(pathname) === 0);

		if (pathname === "/sejour")
			item = seo.find((s) => s.page.indexOf(search) > 0);

		if (!item) item = seo[0];

		return item;
	}, [pathname, search]);

	const { title, description } = getSeoItem;

	return (
		<Helmet>
			<title>{title}</title>
			{/* Facebook Tags */}
			<meta name="description" content={description} />
			<meta property="og:url" content={window.location.href} />
			<meta property="og:title" content={title} />
			<meta property="og:description" content={description} />
		</Helmet>
	);
};

export default SEO;
