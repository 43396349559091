import ConditionArticle from "../ConditionArticle/condition-article.component";
import { ConditionContainer } from "./condition.styled";

const Condition = ({ title, articles }) => {
	return (
		<ConditionContainer>
			<h1 className="opensans-extra-bold-black-pearl-20px">{title}</h1>
			{articles &&
				articles.map((a, ix) => (
					<ConditionArticle article={a} key={`article-${ix}`} />
				))}
		</ConditionContainer>
	);
};

export default Condition;
