import TucanLogo from "../TucanLogo/tucan-logo.component";
import "./loading.css";

const Loading = ({ size = "big" }) => {
	return (
		<div className="tucan-loading">
			<TucanLogo className={size} />
		</div>
	);
};

export default Loading;
